@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-color: rgb(0, 0, 0);
  --background-color: rgb(255, 255, 255);
  --offset-color: rgba(241, 241, 241, 1);

  --spacing-big: 25px;
  --spacing-medium: 15px;
  --spacing-small: 12px;

  --base-size: 16px;
}

html,
body {
  @apply h-screen;
  color: var(--foreground-color);
  background: var(--offset-color);
  font-size: var(--base-size);
}

a {
  color: rgba(72, 72, 72, 1);
  text-decoration-color: rgba(72, 72, 72, 1);
}

a:hover {
  text-decoration: underline;
}
